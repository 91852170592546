import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const Opleidingen = () => {
  const data = useStaticQuery(graphql`
    query {
      zipperCoach: file(relativePath: { eq: "zipper-coaching-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      companyTraining: file(relativePath: { eq: "incompany-training.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reattachTraining: file(relativePath: { eq: "reattach.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      combined: file(relativePath: { eq: "combined-training.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout button={false}>
      <SEO
        title=" Zipperr® Opleidingen"
        description="Erkende Opleidingen op gebied van Zipperr coaching, reAttach, Profile dynamics en incompany trainingen "
      />
      <div className="containerPad bgalt">
        <h1 style={{ textAlign: "center" }}>
          Kies voor een van de Zipperr® coaching training
        </h1>
        <div className={"block-grid"}>
          <Link to={"/opleidingen/coachopleiding"} className="inner-block-grid">
            <div className="inner-block-grid-image">
              <Img
                fluid={data.zipperCoach.childImageSharp.fluid}
                objectFit="cover"
                // objectPosition="50% 50%"
                alt="Zipperr Coaching"
                // style={{maxHeight: '45%'}}
                // className={'caseimg'}
              />
            </div>
            <div className="inner-block-grid-title">
              <h2>Zipperr® Coachopleiding</h2>
            </div>
          </Link>

          <Link
            to={"/opleidingen/reattachcoachopleiding"}
            className="inner-block-grid"
          >
            <div className="inner-block-grid-image">
              <Img
                fluid={data.reattachTraining.childImageSharp.fluid}
                objectFit="cover"
                // objectPosition="50% 50%"
                alt="Zipperr In Company Coaching"
                // style={{maxHeight: '45%'}}
                // className={'caseimg'}
              />
            </div>
            <div className="inner-block-grid-title">
              <h2>ReAttach Coachopleiding</h2>
            </div>
          </Link>

          <Link
            to={"/opleidingen/zippercaseopleiding"}
            className={"inner-block-grid"}
          >
            <div className="inner-block-grid-image">
              <Img
                fluid={data.combined.childImageSharp.fluid}
                objectFit="cover"
                // objectPosition="50% 50%"
                alt="Zipperr In Company Coaching"
                // style={{maxHeight: '45%'}}
                // className={'caseimg'}
              />
            </div>
            <div className="inner-block-grid-title">
              <h2>Zipperr® case opleiding</h2>
            </div>
          </Link>

          <Link to={"/opleidingen/enneagram"} className={"inner-block-grid"}>
            <div className="inner-block-grid-image">
              <Img
                fluid={data.companyTraining.childImageSharp.fluid}
                objectFit="cover"
                // objectPosition="50% 50%"
                alt="Zipperr In Enneagram opleiding"
                // style={{maxHeight: '45%'}}
                // className={'caseimg'}
              />
            </div>
            <div className="inner-block-grid-title">
              <h2>Enneagram opleiding Zipperr style</h2>
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default Opleidingen
